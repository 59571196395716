import { Box, Typography } from "@mui/material";

export default function Heading({ title, text }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        py: 1,
        gap: 1,
        textAlign: "center",
      }}
    >
      <Typography variant={"h5"} fontWeight="bold">
        {title}
      </Typography>
      <Box>{text}</Box>
    </Box>
  );
}
