import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function ResponsiveStepper({
  steps,
  activeStep,
  children = [],
  sx = {},
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={sx}>
      <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
        {steps.map(({ label }, index) => (
          <Step key={`step-${index}`}>
            <StepLabel>
              {isMobile ? null : index === activeStep ? label : null}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {children}
    </Box>
  );
}
