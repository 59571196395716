import { useState } from "react";

const validActions = ["validateRut", "signup", "eventSignup"];

export default function useRecaptcha() {
  const [recaptchaToken, setRecaptchaToken] = useState("");

  async function getFreshToken(action = "signup") {
    if (!window.grecaptcha) {
      return;
    }

    try {
      if (!validActions.includes(action)) {
        throw new Error(`Invalid recaptcha action: ${action}`);
      }

      const token = await window.grecaptcha.execute(
        process.env.GOOGLE_RECAPTCHA_SITE_KEY,
        { action }
      );
      setRecaptchaToken(token);
    } catch (error) {
      console.error("Error ejecutando grecaptcha:", error);
    }
  }

  const captchaScriptProps = {
    src: `https://www.google.com/recaptcha/api.js?render=${process.env.GOOGLE_RECAPTCHA_SITE_KEY}`,
    strategy: "afterInteractive",
  };

  return { recaptchaToken, getFreshToken, captchaScriptProps };
}
