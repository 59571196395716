export const translateError = (error, context = "") => {
  if (error.graphQLErrors) {
    console.log("error.graphQLErrors", error.graphQLErrors);
    return error.graphQLErrors.map((graphQLError) => {
      return translateGraphQLError(graphQLError, context);
    });
  }
  return error.message;
};

const translateGraphQLError = (graphQLError, context) => {
  const { message, extensions } = graphQLError;

  if (extensions?.message.includes("duplicate key")) {
    if (context === "signup") return "El correo ya se encuentra en uso.";
    return "Error de clave duplicada.";
  } else if (extensions?.message.includes("correlationId")) {
    return "Código de referido no es válido.";
  }

  return message;
};
