import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GENERATE_SIGNUP_TOKEN,
  VALIDATE_EMAIL,
  VALIDATE_RUT,
} from "queries/session";
import { POST_SIGNUP } from "mutations/session";
import client from "contexts/session/signupApolloClient";
import { useSignIn } from "hooks/session";
import { translateError } from "utils/_MRutils/errorHelpers";
import { useAuth } from "contexts";

export default function useSecureSignup({ onTokenExpiration = () => {} }) {
  const [fetchToken, { loading: fetchLoading, data }] = useLazyQuery(
    GENERATE_SIGNUP_TOKEN
  );

  const { fingerprint } = useAuth();
  const [loading, setLoading] = useState(true);
  const [validationLoading, setValidationLoading] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);

  const { loading: signinLoading, onSubmit: signinOnSubmit } = useSignIn();

  useEffect(() => {
    if (fingerprint) {
      fetchToken({ variables: { fingerprint } });
    }
  }, [fingerprint]);

  useEffect(() => {
    if (data && !fetchLoading) {
      setLoading(false);
      localStorage.setItem("SUP_TOKEN", data.generateSignupToken);
    }
  }, [data, fetchLoading]);

  async function validateEmail(email) {
    try {
      setValidationLoading(true);
      await client.query({
        query: VALIDATE_EMAIL,
        variables: { email, fingerprint },
        fetchPolicy: "no-cache",
      });
      return { success: true };
    } catch (error) {
      const errMessage =
        error?.graphQLErrors[0]?.extensions?.message || "Error desconocido";
      if (errMessage.includes("token")) {
        onTokenExpiration();
        return { success: false, error: "Tiempo se ha agotado." };
      }
      return {
        success: false,
        error: errMessage,
      };
    } finally {
      setValidationLoading(false);
    }
  }

  async function validateRut(rut, recaptchaToken) {
    try {
      setValidationLoading(true);
      const { data } = await client.query({
        query: VALIDATE_RUT,
        variables: { rut, fingerprint, recaptchaToken },
        fetchPolicy: "no-cache",
      });
      return { success: true, data: data.validateRut.data };
    } catch (error) {
      const errMessage =
        error?.graphQLErrors[0]?.extensions?.message || "Error desconocido";
      if (errMessage.includes("token")) {
        onTokenExpiration();
        return { success: false, error: "Tiempo se ha agotado." };
      }

      return {
        success: false,
        error: errMessage,
      };
    } finally {
      setValidationLoading(false);
    }
  }

  async function submitSignup(data, recaptchaToken) {
    try {
      setSignupLoading(true);
      await client.mutate({
        mutation: POST_SIGNUP,
        variables: { ...data, fingerprint, recaptchaToken },
        fetchPolicy: "no-cache",
      });
      return {
        success: true,
        message: "¡Registrado correctamente!",
        severity: "success",
      };
    } catch (error) {
      return {
        success: false,
        message: translateError(error, "signup"),
        severity: "error",
      };
    } finally {
      setSignupLoading(false);
    }
  }

  async function submitSignin(data, formikInstance) {
    try {
      await signinOnSubmit(
        { ...data, view: "login", skipMetrics: true },
        formikInstance
      );
      return { success: true };
    } catch (error) {
      return {
        success: false,
        severity: "error",
        message: translateError(error, "signin"),
      };
    }
  }

  return {
    loading: fetchLoading || loading,
    validationLoading,
    signupLoading,
    signinLoading,
    validateEmail,
    validateRut,
    submitSignup,
    submitSignin,
  };
}
