import React from "react";
import { Snackbar as MuiSnackbar, Alert } from "@mui/material";

const Snackbar = ({
  open = false,
  onClose = () => {},
  message = "",
  severity = "error",
  autoHideDuration = 3000,
  alert = null,
}) => {
  return (
    <MuiSnackbar
      open={!!alert || open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={alert?.severity || severity}>
        {alert?.message || message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
