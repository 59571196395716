import { Backdrop, CircularProgress, Typography } from "@mui/material";
export default function AppBackdrop({ open = false, text = "Cargando..." }) {
  return (
    <Backdrop open={open} style={{ zIndex: 1301, color: "#fff" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" style={{ marginTop: 10 }}>
          {text}
        </Typography>
      </div>
    </Backdrop>
  );
}
