import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Button as BupButton } from "components";

const NavigationButtons = ({
  activeStep,
  isLastStep,
  handleBack,
  handleNext,
  hideBack,
  hideNext,
  loading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        position: isMobile ? "fixed" : "relative",
        bottom: isMobile ? 0 : "auto",
        left: isMobile ? 0 : "auto",
        right: isMobile ? 0 : "auto",
        width: isMobile ? "100%" : "100%",
        maxWidth: "650px",
        marginTop: isMobile ? 0 : 3,
        backgroundColor: isMobile ? "white" : "none",
        paddingX: isMobile ? 2 : 0,
        paddingY: 2,
        boxShadow: isMobile ? "0px -2px 10px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      {!hideBack && (
        <BupButton
          onClick={handleBack}
          disabled={activeStep === 0}
          variant="outlined"
          sx={{ minWidth: "100px" }}
        >
          Atrás
        </BupButton>
      )}
      {!hideNext && (
        <BupButton
          onClick={handleNext}
          loading={loading}
          sx={{ minWidth: "100px" }}
        >
          {isLastStep ? "Finalizar" : "Continuar"}
        </BupButton>
      )}
    </Stack>
  );
};

export default NavigationButtons;
