import { useState, useEffect } from "react";
import { useFormik } from "formik";
import Yup from "utils/_MRutils/YupValidation";
import { useNetwork } from "contexts";
import { useRouter } from "next/router";

export default function useSignupForm(defaultSignUpCode = null) {
  const { popularInterests } = useNetwork();
  const router = useRouter();
  const { signUpCode, referralCode, source } = router.query;

  const FIELD_CONFIGS = [
    [
      {
        name: "email",
        label: "Correo electrónico",
        type: "email",
      },
      {
        name: "info.phone",
        label: "Teléfono",
        type: "phone",
      },
    ],
    [
      {
        name: "info.rut",
        label: "Rut",
        type: "rut",
      },
    ],
    [
      { name: "info.rut", label: "Rut ingresado", disabled: true },
      { name: "other.names", label: "Nombres", disabled: true },
      { name: "other.lastNames", label: "Apellidos", disabled: true },
      { name: "other.gender", label: "Género", disabled: true },
      { name: "other.birth", label: "Fecha de nacimiento", disabled: true },
    ],
    [
      {
        name: "location",
        label: "Busca tu comuna",
        type: "mapsAutocomplete",
        relatedFields: [
          "info.commune",
          "info.country",
          "info.region",
          "info.city",
        ],
      },
      { name: "info.commune", hidden: true },
      { name: "info.country", hidden: true },
      { name: "info.region", hidden: true },
      { name: "info.city", hidden: true },
    ],
    [
      {
        name: "email",
        label: "Correo electrónico",
        disabled: true,
      },
      {
        name: "password",
        label: "Contraseña",
        disabled: true,
      },
    ],
  ];

  const [signupFormikInitialValues, setSignupFormikInitialValues] = useState({
    location: "",
    email: "",
    password: "",
    info: {
      firstName: "",
      lastName: "",
      rut: "",
      birth: null,
      gender: "",
      phone: "",
      country: "",
      region: "",
      city: "",
      commune: "",
    },
    interests: [],
    signUpCode: defaultSignUpCode || "",
    source: "WEB",
    other: {
      names: "",
      lastNames: "",
      gender: "",
      birth: "",
    },
  });

  const signupFormik = useFormik({
    initialValues: signupFormikInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      location: Yup.string().required("Campo requerido"),
      email: Yup.string()
        .email("Debe ser un correo válido")
        .required("Campo requerido"),
      password: Yup.string().min(6).required("Campo requerido"),
      info: Yup.object().shape({
        firstName: Yup.string().required("Campo requerido"),
        lastName: Yup.string().required("Campo requerido"),
        rut: Yup.string().isRut().required("Campo requerido"),
        birth: Yup.date().required("Campo requerido"),
        gender: Yup.string().required("Campo requerido"),
        phone: Yup.string().required("Campo requerido"),
        region: Yup.string(),
        city: Yup.string(),
        country: Yup.string(),
        commune: Yup.string().required("Campo requerido"),
      }),
      interests: Yup.array().of(Yup.string()).min(1),
      signUpCode: Yup.string().optional(),
      referralCode: Yup.string().optional(),
      integrationPayload: Yup.object()
        .shape({
          token: Yup.string().optional(),
          provider: Yup.string().optional(),
        })
        .nullable(),
    }),
  });

  useEffect(() => {
    if (signUpCode || referralCode || source || popularInterests) {
      setSignupFormikInitialValues({
        ...signupFormikInitialValues,
        ...(signUpCode && !defaultSignUpCode ? { signUpCode } : {}),
        ...(referralCode ? { referralCode } : {}),
        ...(source ? { source } : {}),
        ...(popularInterests ? { interests: popularInterests } : {}),
      });
    }
  }, [signUpCode, referralCode, source, popularInterests]);

  return { FIELD_CONFIGS, signupFormik };
}
