import { Box, Button, Icon } from "components";
import { helper, openUrl } from "utils";

const GenericMap = ({
  disableLink,
  hidden,
  address,
  lat,
  lng,
  mapUrl,
  name,
  zoom,
}) => {
  if (hidden || mapUrl) return;

  const src = helper.generateGoogleMapsEmbed({
    address,
    lat,
    lng,
    mapUrl,
    name,
    zoom,
  });

  return (
    <Box className="BupDocument-map">
      <iframe
        allowFullScreen={true}
        className="BupDocument-googleMap"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        src={src}
        style={{ width: "100%", height: "400px", border: "none" }}
      ></iframe>
      <Button
        className="BupItem-action"
        color="inherit"
        endIcon={<Icon name="open_in_new" />}
        variant="outlined"
        hidden={disableLink}
        onClick={() => openUrl(src)}
        disableElevation
      >
        Cómo llegar
      </Button>
    </Box>
  );
};

export default GenericMap;
